import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

const SearchInput = props => {
  const { location } = props

  const [searchInput, setSearchInput] = useState("")

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const query = params.get("q") || ""
    setSearchInput(query)
  }, [])

  useEffect(() => {
    let searchData = []
    CollectionsName.map(collection => {
      if (data[collection]) {
        searchData.push(...data[collection].edges.map(item => item.node.data))
      }
    })

    const searchQuery = new URLSearchParams(location.search).get("q") || ""
    if (searchQuery) {
      handleSearchData({ searchQuery, searchData })
    } else {
      setFilteredData(searchData)
    }
  }, [location.search])

  const handleSearchData = ({ searchQuery, searchData }) => {
    const keywords = searchQuery.toLowerCase().trim().split(/\s+/)

    const filtered = searchData.filter(item => {
      const title = item.product_title.toLowerCase()

      if (title.includes(searchQuery.toLowerCase().trim())) return true

      return keywords.every(word => title.includes(word))
    })

    setFilteredData(filtered)
  }

  const handleChange = value => {
    setSearchInput(value)

    if (window.__searchTimeout) {
      clearTimeout(window.__searchTimeout)
    }

    window.__searchTimeout = setTimeout(() => {
      const url = new URL(window.location)

      if (value) {
        url.searchParams.set("q", value)
      } else {
        url.searchParams.delete("q")
      }

      navigate(`${url.pathname}?${url.searchParams.toString()}`, {
        replace: true,
      })
    }, 500)
  }

  return (
    <div className="flex flex-col items-center w-full px-4">
      <h2 className="text-2xl font-extrabold mb-4">Search result</h2>
      <div className="relative w-96 md:w-[500px]">
        <input
          type="text"
          value={searchInput}
          onChange={e => handleChange(e.target.value)}
          placeholder="Search Color, Category, Title"
          className="w-full px-6 py-3 pr-12 rounded-full border border-gray-300 focus:outline-none focus:ring-1 focus:ring-black focus:border text-gray-500 bg-gray-100"
        />
        <button
          onClick={() => handleChange("")}
          className="absolute right-8 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-black"
        >
          <svg viewBox="0 0 20 20" width="12" height="12" aria-hidden="true">
            <path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"></path>
          </svg>
        </button>
      </div>
    </div>
  )
}

export default SearchInput
